export interface MenuItem {
  url: string,
  name: string,
  style?: 'button',
};

export const menu: MenuItem[] = [
  //{ name: 'Home', url: '/' },
  //{ name: 'Services', url: '/services' },
  //{ name: 'Approach', url: '/approach' },
  //{ name: 'Projects', url: '/projects' },
  //{ name: 'Blog', url: '/posts' },
  { name: 'Contact', url: '/#contact', style: 'button' },
];

export default menu;