import { Link, graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import Img from 'gatsby-image';
import { Button, Box, SwipeableDrawer, MenuItem, makeStyles, Typography } from '@material-ui/core';
import { Menu, Close } from '@material-ui/icons';
import { useIsLargeScreen } from "../hooks/useIsLargeScreen";
import menuItems from '../menu';
import { StyledButton } from './UI/StyledButton';
import { useLocation } from '@reach/router';
import { Container } from "./UI/Container";

const useMobileMenuStyles = makeStyles( theme => ({
  root: {
    '& a': {
      color: '#000000b5',
      '&:hover': {
        color: theme.palette.primary.light,
      },
    },
    '& li': {
      height: '3rem',
      lineHeight: '3rem',
    },
  },
}) );

export const Header = ( ) => {
  const [ isDrawerOpen, setDrawerOpen ] = useState( false );

  const data = useStaticQuery(
    graphql`
      query {
        logo: file(relativePath: { eq: "icon.png" } ) {
          childImageSharp {
            fixed(height: 32) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
      }
    `
  );

  const isLargeScreen = useIsLargeScreen();

  useEffect( () => {
    if ( isLargeScreen )
      setDrawerOpen( false );    
  }, [ isLargeScreen ] );

  const location = useLocation();
  useEffect( () => {
    setDrawerOpen( false );
  }, [ location ] );

  const textStyles: React.CSSProperties = {
    pointerEvents: 'auto',
    background: `#fcfdfc${ isLargeScreen ? '90' : 'c4' }`,
    backdropFilter: 'blur(0.6px)',
    zIndex: 1,
  };  

  const classes = useMobileMenuStyles();

  const menu = <>
    <Box display={{ xs: 'none', md: 'flex' }} flex="shrink" margin="8px 0" justifyContent="flex-end" alignItems="center" style={ textStyles }>
      { menuItems.map( (item,i) =>
        <Link key={i} to={item.url}>
          { item.style === 'button' 
              ? <StyledButton>{ item.name }</StyledButton>
              : <Button style={{ padding: '4px 12px' }}>{ item.name }</Button>
          }
        </Link>
      ) }
    </Box>
    <Box display={{ xs: 'block', md: 'none' }} style={{ pointerEvents: 'auto' }}>
      <Menu onClick={ () => setDrawerOpen( true ) } style={{ cursor: 'pointer', fontSize: '2.8rem' }} />
      <SwipeableDrawer open={ isDrawerOpen } onOpen={ () => setDrawerOpen( true ) } onClose={ () => setDrawerOpen( false ) } disableBackdropTransition disableDiscovery>
        <Box width="90vw" className={ classes.root } p={'12px 16px'} fontSize="1.4rem">
          <Box p={'8px 4px'} mb={2} display="flex" justifyContent="space-between" alignItems="flex-start">
            <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
              <Box flex={0} mr="8px">
                <Img fixed={data.logo.childImageSharp.fixed} alt="Resolute Web Ltd." />
              </Box>
              <Box flex={1}>
                <Typography
                  variant="h1"
                  style={{ 
                    fontFamily: 'Signika',
                    fontWeight: 500,
                    color: '#000',
                    fontSize: '2rem',
                    letterSpacing: '-0.04rem',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Resolute Web
                </Typography>
              </Box>
            </Link>
            <Close onClick={ () => setDrawerOpen( false ) } style={{ cursor: 'pointer', fontSize: '1.4rem' }} />
          </Box>
          { menuItems.map( (item,i) =>
            <React.Fragment key={i}>
              { item.style === 'button'
                ? <Box mt={4} display="flex" justifyContent="center"><Link to={ item.url }><StyledButton>{ item.name }</StyledButton></Link></Box>
                : <Link to={ item.url }><MenuItem>{ item.name }</MenuItem></Link>
              }
            </React.Fragment>
          ) }
        </Box>
      </SwipeableDrawer>
    </Box>
  </>;

  return (
    <Box display="flex" alignItems="center">
      <header style={{ width: '100%' }}>
        <Container>
          <Box width="100%" m="auto" p={{ xs: '12px 18px', md: '48px' }} display="flex" justifyContent="space-between" alignItems={{ xs: 'flex-start', md: 'center' }} style={{ pointerEvents: 'none' }}>
            <Box p={'8px 4px'} flex="shrink" lineHeight={0} style={ textStyles }>
              <Link to="/">
                <Box display="flex" alignItems="center" style={{ userSelect: 'none' }}>
                  <Box mr={1}>
                    <Img loading="eager" fixed={data.logo.childImageSharp.fixed} alt="Resolute Web Ltd." />
                  </Box>
                  <Typography
                    variant="h1"
                    style={{ 
                      fontFamily: 'Signika',
                      fontWeight: 500,
                      color: '#000',
                      fontSize: '2rem',
                      letterSpacing: '-0.04rem',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Resolute Web
                  </Typography>
                </Box>
              </Link>
            </Box>
            <Box flex="shrink" zIndex={1}>
              { menu }
            </Box>
          </Box>
        </Container>
      </header>
    </Box>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
