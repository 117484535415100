/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import { Box } from "@material-ui/core"

export interface LayoutProps {
  children: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = ( props ) => {
  const { children } = props;

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      background: file(relativePath: { eq: "background.png" } ) {
        childImageSharp {
          fixed(width: 1920, height: 1080) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      <Box flex={1}>
        <Header />
        <main>
          {children}
        </main>
      </Box>
      <Box mt="auto">
        <footer>
          <Box height={80} display="flex" justifyContent="center" alignItems="center">
            &copy; Resolute Web Ltd. 
          </Box>
        </footer>
      </Box>
    </Box>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;