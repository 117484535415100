import React from 'react';
import { Button as MuiButton, ButtonProps, makeStyles } from '@material-ui/core';

const useStyles = makeStyles( {
  root: {
    flex: 'shrink',
    display: 'block',
    margin: '0 12px',
    padding: '4px 12px',
    borderRadius: '32px',
  },
} );

export const StyledButton: React.FC<ButtonProps> = ( props ) =>
 <MuiButton classes={ useStyles() } variant="contained" color="primary" { ...props } />
;

export default StyledButton;